import React from "react";

//styles
import * as S from "./styles";

//components
import WorkshopTypePrintable from "templates/WorkshopTypePrintable";
import Page from "components/Page";

class WorkshopPrintable extends React.Component {
  render() {
    const { pageContext } = this.props;
    const { workshop, workshopExtra, settings } = pageContext;
    const { workshopType, slug } = workshop;
    const { meta, dates, timeStart, timeEnd } = workshopExtra;

    return (
      <Page
        pageTitle="React Academy Workshop"
        title={meta.title}
        description={meta.description}
        url={`https://reactacademy.io/workshop/${slug}`}
        imageUrl={`https://react-academy-meta.netlify.com/${slug}.jpg`}
      >
        <S.Page>
          {workshop.conferenceName && (
            <S.Section>
              <S.Title> Conference </S.Title>
              <S.Text>
                <a target="_blank" rel="noopener" href={workshop.conferenceUrl}>
                  {workshop.conferenceName}
                </a>
              </S.Text>
            </S.Section>
          )}

          <S.Section>
            <S.Title> Location </S.Title>
            <S.Text>
              {workshop.city}, {workshop.country}
            </S.Text>
          </S.Section>

          <S.Section>
            <S.Title> Date </S.Title>
            <S.Text>{dates}</S.Text>
          </S.Section>

          <S.Section>
            <S.Title> Time </S.Title>
            <S.Text>
              {timeStart} - {timeEnd}
            </S.Text>
          </S.Section>

          {workshop.ticketsLink && (
            <S.Section>
              <S.Title> Tickets Link </S.Title>
              <S.Text>
                <a target="_blank" rel="noopener" href={workshop.ticketsLink}>
                  {workshop.ticketsLink}
                </a>
              </S.Text>
            </S.Section>
          )}

          <WorkshopTypePrintable
            wrapPage={false}
            pageContext={{
              settings,
              workshopType
            }}
          />
        </S.Page>
      </Page>
    );
  }
}

export default WorkshopPrintable;
