import emotion from '@emotion/styled';

export const Page = emotion.div(
  {
    padding: 20,
    fontFamily: 'Arial',
    '@media print': {
      'a:visited': {
        color: '#0001ee'
      }
    }
  },
  ({ wrapPage }) => ({
    ...(wrapPage === false && {
      padding: 0
    })
  })
);

export const Title = emotion.div({
  fontSize: 22,
  fontWeight: 'bold',
  marginBottom: 10
});

export const Section = emotion.div({
  marginBottom: 20
});

export const Text = emotion.div({
  fontSize: 18
});

export const Questions = emotion.div({});

export const FAQ = emotion.div({});

export const Question = emotion.div({
  fontWeight: 'bold',
  fontSize: 18
});

export const Answer = emotion(Text)({
  '& p': {
    padding: 0,
    margin: 0
  },
  '& ul': {
    margin: 0,
    marginBottom: 15
  },
  padding: '10px 0'
});
