import React from "react";

import createCompile from "config/compile-markdown";
import * as S from "./styles";
import socialImageDefault from "config/default-social-image";

//components
import Page from "components/Page";

const compile = createCompile();

class WorkshopTypePrintable extends React.Component {
  render() {
    const { pageContext, wrapPage } = this.props;
    const { workshopType, settings } = pageContext;
    const { questions = [], topics = [] } = workshopType;

    return (
      <Page
        pageTitle="React Academy Workshop"
        title={workshopType.title}
        description={workshopType.description}
        url={`https://www.reactacademy.io/workshop-type/${workshopType.slug}`}
        imageUrl={socialImageDefault}
      >
        <S.Page wrapPage={wrapPage}>
          <S.Section>
            <S.Title>Workshop type</S.Title>
            <S.Text>{workshopType.title}</S.Text>
          </S.Section>

          <S.Section>
            <S.Title>Description</S.Title>
            <S.Text>{workshopType.description}</S.Text>
          </S.Section>

          <S.Section>
            <S.Title>Prerequisites</S.Title>
            <S.Text>{workshopType.prerequisite}</S.Text>
          </S.Section>

          <S.Section>
            <S.Title>Topics</S.Title>
            <S.Questions>
              {topics.map(topic => (
                <S.FAQ key={topic.id}>
                  <S.Question>{topic.title}</S.Question>
                  <S.Answer> {topic.description} </S.Answer>
                </S.FAQ>
              ))}
            </S.Questions>
          </S.Section>

          <S.Section>
            <S.Title>FAQ</S.Title>
            <S.Questions>
              {questions.map(faq => (
                <S.FAQ key={faq.id}>
                  <S.Question>{faq.question}</S.Question>
                  <S.Answer> {compile(faq.answer).tree} </S.Answer>
                </S.FAQ>
              ))}
            </S.Questions>
          </S.Section>

          {settings && (
            <S.Section>
              <S.Title>Instructor</S.Title>
              <S.Text>{compile(settings.instructor).tree}</S.Text>
            </S.Section>
          )}
        </S.Page>
      </Page>
    );
  }
}

export default WorkshopTypePrintable;
